@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  * {
    @apply p-0;
    @apply m-0;
    @apply box-border;
  }
  body {
    @apply overflow-x-hidden;
    @apply bg-backgroundAsh;
  }

  html,
  body {
    @apply font-Poppins;
    @apply w-full;
  }
  li {
    @apply cursor-pointer;
    @apply font-normal;
  }
  .table-list {
    @apply relative;
    @apply text-[#666];
    @apply capitalize;
    @apply mb-[18px];
    @apply pr-[32px];
    @apply text-sm;
  }

  .sub-list p {
    @apply text-[#757575];
    @apply leading-6;
    @apply font-normal;
    @apply text-left;
    @apply text-sm;
  }

  .form-inputs {
    @apply w-full;
    @apply h-[50px];
    @apply border;
    @apply border-[#eee];
    @apply px-[18px];
    @apply text-[#555];
    @apply text-sm;
    @apply font-normal;
    @apply rounded;
  }

  .contact-form-inputs {
    @apply w-full;
    @apply h-[40px];
    @apply border;
    @apply border-[#eee];
    @apply py-0;
    @apply px-[18px];
    @apply text-[#555];
    @apply text-sm;
    @apply font-normal;
    @apply rounded-[4px];
  }

  .contact-form-inputs::placeholder {
    @apply capitalize;
  }
  .quote-form-inputs {
    @apply border-[#ccc];
  }
  .contact-form-textarea {
    @apply h-[100px];
    @apply p-[18px];
    @apply rounded-[4px];
  }
  .article p {
    @apply mb-[20px];
  }
  .article h5 {
    @apply text-[1.2rem];
    @apply font-semibold;
    @apply my-[20px];
    @apply text-[#2C2D3F];
  }
  .preloader-deactivate::before {
    @apply w-0;
  }
}

:root {
  --primary: #ff4848;
  --primary-dark: #ed5600;
}
